<template>
  <svg viewBox="0 0 24 24">
    <path
      d="M7.828 10.9997H20V12.9997H7.828L13.192 18.3637L11.778 19.7777L4 11.9997L11.778 4.22168L13.192 5.63568L7.828 10.9997Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowIcon",
};
</script>
